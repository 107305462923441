import {
  FormDescription,
  FormSection,
  FormSectionContent,
  FormSectionHeader,
} from "../FormSection";

import AddProductsProductListItem from "./addProductsToProductGroup/AddProductsProductListItem";
import AddProductsToGroupModal from "./addProductsToProductGroup/AddProductsToGroupModal";
import { DataHubButton } from "../DataHubButton";
import EditProductsToGroupModal from "./addProductsToProductGroup/EditProductsToGroupModal";
import { HasuradbDeletedProduct, HasuradbProduct } from "../../graphqlQueries/getProduct";
import React from "react";
import { RoleGroups } from "../../types";
import { getEditPath } from "../../utils/productGroupUtils";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userInfoVar } from "../../utils/ApolloCache";
import DeletedProductsProductListItem from "./addProductsToProductGroup/DeletedProductsProductListItem";

type AddProductsToGroupProps = {
  rootPagePath: string;
  groupId: string;
  products: HasuradbProduct[];
  setProducts: (products: HasuradbProduct[]) => void;
  deletedProducts: HasuradbDeletedProduct[];
  setDeletedProducts: (products: HasuradbDeletedProduct[]) => void;
  isLocked?: boolean;
  modalOpen: boolean;
  setModalOpen: (val: boolean) => void;
  setIsEditingProductId: (val: string | undefined) => void;
  isEditingProductId?: string;
  canManage?: boolean;
  productsFromDb?: { product: HasuradbProduct }[];
  handleProductEditing: (values: HasuradbProduct) => void;
  editedProductsData?: HasuradbProduct[];
};

const AddProductsToGroup = ({
  rootPagePath,
  groupId,
  products,
  setProducts,
  deletedProducts,
  setDeletedProducts,
  isLocked,
  modalOpen,
  setModalOpen,
  canManage,
  setIsEditingProductId,
  isEditingProductId,
  productsFromDb,
  handleProductEditing,
  editedProductsData,
}: AddProductsToGroupProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const userInfo = userInfoVar();
  const tcCompanyIds =
    (userInfo?.roleGroups?.includes(RoleGroups.TCAdmins) && userInfo?.companyIds) || undefined;

  const handleProductAdd = (productListItems: HasuradbProduct[]) => {
    setProducts(productListItems);
  };

  const closeModal = () => {
    if (groupId) {
      history.replace(getEditPath(rootPagePath, groupId));
    }
    setModalOpen(!modalOpen);
  };

  const editProds = (): HasuradbProduct | undefined => {
    // Either edited product from DB or a non-edited product from DB
    const editedProduct =
      editedProductsData?.find((product) => product.id === isEditingProductId) ||
      productsFromDb?.find((product) => product.product.id === isEditingProductId)?.product;

    //Product that is edited but has not been saved yet
    const notSavedProduct =
      editedProductsData?.find((product) => product.id === isEditingProductId) ||
      products?.find((product) => product.id === isEditingProductId);

    // NotSavedProduct should have #1 priority to be displayed, then editedproduct
    return notSavedProduct !== editedProduct ? notSavedProduct : editedProduct;
  };

  return (
    <FormSection>
      <FormDescription>
        <FormSectionHeader>{t("groupedProducts.productsHeader")}</FormSectionHeader>
        <div>{t("groupedProducts.productsInstructions")}</div>
      </FormDescription>
      <FormSectionContent>
        <div>
          <DataHubButton
            variant="primary"
            className="mb-3"
            disabled={isLocked}
            onClick={() => setModalOpen(!modalOpen)}
          >
            + {t("groupedProducts.addProducts")}
          </DataHubButton>
        </div>
        {deletedProducts && deletedProducts.length > 0 && (
          <div className={"pb-3"}>
            {deletedProducts.map((product) => (
              <DeletedProductsProductListItem
                key={product.id}
                item={product}
                onButtonClick={() =>
                  setDeletedProducts(deletedProducts.filter((p) => p.id !== product.id))
                }
              />
            ))}
          </div>
        )}
        <div>
          {products.length === 0 && t("groupedProducts.noProductsInGroup")}
          {products.length > 0 && (
            <div>
              <div className="mb-3">
                {t("groupedProducts.productsAmount", { amount: products.length })}
              </div>
              {[...products].reverse().map((product) => (
                <AddProductsProductListItem
                  key={product.id}
                  item={product}
                  selected={false}
                  canManage={canManage}
                  onButtonClick={() => setProducts(products.filter((p) => p.id !== product.id))}
                  onEdit={() => setIsEditingProductId(product.id)}
                  isPreview={true}
                  isLocked={isLocked}
                  hasBeenConfirmed={true}
                  tcCompanyIds={tcCompanyIds}
                />
              ))}
            </div>
          )}
        </div>
      </FormSectionContent>
      {modalOpen && (
        <AddProductsToGroupModal
          onHide={closeModal}
          onSave={(prods) => handleProductAdd(prods)}
          rootPagePath={rootPagePath}
          groupId={groupId}
          oldProducts={products}
          tcCompanyIds={tcCompanyIds}
        />
      )}
      {isEditingProductId && (
        <EditProductsToGroupModal
          onHide={() => setIsEditingProductId(undefined)}
          product={editProds()}
          handleProductEditing={handleProductEditing}
          productGroupId={groupId}
        />
      )}
    </FormSection>
  );
};

export default AddProductsToGroup;
